<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12" class="d-flex  pl-5">
                    <v-row>
                        <appGenVariables @Submitted="fetchData" />
                    </v-row>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from" clearable>
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to" clearable>
                    </v-text-field></v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('Registration Record Number')" type="number"
                        v-model="filters.public_record_number" clearable>
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('student name')" type="regRecord" v-model="filters.registration_record_id"
                        endPoint="/student/auto-complete" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('class')" v-model="filters.grade_id" endPoint="/grade" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class_id"
                        endPoint="/edu-class" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('Variable Name')" v-model="filters.variable_id"
                        endPoint="/school-variable" />
                </v-col>
                <v-col md="3">
                    <v-select v-model="filters.variable_type" :dark="$store.state.isDarkMode" :label="$t('Variable Type')"
                        :items="variableTypeList"></v-select>
                </v-col>
                <v-col md="3">
                    <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('Group By')" :items="group_by" item-text="name" item-value="value"
                        v-model="filters.group_by"></v-autocomplete>
                </v-col>

                <v-col md="6">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('notes')" v-model="filters.note" clearable>
                    </v-text-field>
                </v-col>

                <v-col md="3"></v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0" v-if="returnAbility('student-variable:exporف')">
                    <ExportExcel end-point="/student-variable/export" :params="filters" />
                </v-col>
                <v-col md="12">
                    <v-container id="regular-tables" fluid tag="section">
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading" v-if="!tableOptions.loading">
          <tr>
            <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <div class="table__spinnerWrapper" v-if="tableOptions.loading">
          <app-spinner></app-spinner>
        </div>
        <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!tableOptions.loading">
          <template  v-for="data in tableOptions.tableData" >
            <tr :key="data.uid" style=" background: var(--group-by-col)">
              <th colspan="8" style=" border: 0; font-size: 1rem">
                <span v-if="data.key"> {{ data.key }} </span>
              </th>
            </tr>
            <tr v-for="(item, idx) in data.value" :key="idx">
              
              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.student_name }}</td>
              <td>{{ item.grade }}</td>
              <td>{{ item.edu_class }}</td>
              <td>{{ item.variable_name }}</td>
              <td>{{ item.variable_type }}</td>
              <td>
                <deleteVar   @record-deleted="fetchData" class="py-2" path="student-variable" :id="item.id" />
                <appGenVariables :is-new="false" :item="item" @Submitted="fetchData" />
                <!-- v-if="returnAbility('final_grade:student:delete')" -->
              </td>
              
            </tr>
          </template>
        </v-fade-transition>
      </v-simple-table>
      <div class="d-flex justify-center mt-5" >
        <v-row>
          <!-- <v-col md="5 ">
            <v-switch
              @change="goToAll"    
              v-model="is_all"
              color="green"
              :label="$t('Show All')"
            ></v-switch>
          </v-col> -->
        <app-pagination v-if="(!tableOptions.loading)"  :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
          @PaginationValue="applyPagination($event)"></app-pagination>
        </v-row>
      </div>
    </v-container>
   
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import axios from "axios";
import appGenVariables from "./genVariables.vue";
import ExportExcel from "../../components/core/ExportExcel.vue";
import ExportPdf from "../../components/core/ExportPdf.vue";
import deleteVar from "../../components/core/delete.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import spinner from "../../../../components/base/spinner.vue";
import pagination from "../../../../components/base/pagination.vue";

export default {
    components: {
        appGenVariables,
        ExportExcel,
        ExportPdf,
        deleteVar,
        AutoCompleteField,
        appSpinner: spinner,
        appPagination: pagination,
    },
    data() {
        return {
            loading: false,
            filters: {
                grade_id: null,
                edu_class_id: null,
                registration_record_id: null,
                public_record_number: null,
                variable_type: null,
                variable_id: null,
                grade: null,
                from: null,
                to: null,
                group_by: "edu_class",
            },
            variableTypeList: ['POSITIVE', 'NEGATIVE', 'MANAGMENT'],
            group_by: [
                {
                    name: "الشعبة",
                    value: "edu_class",
                },
                {
                    name: "الصف",
                    value: "grade",
                },

                {
                    name: "اسم الطالب",
                    value: "student_name",
                },
                {
                    name: "التاريخ",
                    value: "date",
                },
                {
                    name: "اسم المتغير",
                    value: "variable_name",
                },
            ],
            tableOptions: {
                tableData: [],
                tableHeaders: ["id",  "date","name", "class", "educitional class", "Variable Name", "Variable Type", "settings"],
                accessTableData: [["id"], ["date"], ["student_name"], ["grade"], ["edu_class"], ["variable_name"], ["variable_type"]],
                loading: false,
                tableMeta: {
                    total: null,
                    page: null,
                },
            },
        };
    },
    methods: {
        applyPagination(paginationValue) {
      this.page = paginationValue;
      this.fetchData();
    },
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get("/student-variable/report", { params });
            if (response.status == 200) {
                console.log(response.data.data);
                
                this.tableOptions.tableData = response.data.data,
                    this.tableOptions.tableMeta.total = response.data.total;
                this.tableOptions.tableMeta.page = response.data.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
            this.filters.grade_type_id = undefined;
            this.filters.edu_class_id = undefined;
            this.filters.educational_year_id = undefined;
            this.filters.percent = undefined;
            this.filters.label = undefined;
            this.filters.student_id = undefined;
            this.filters.final_grade_id = undefined;
            this.fetchData(1);
        },
    },
    created() {

        this.fetchData(1);
    },
};
</script>

<style></style>
